
import React from "react";
import { MdLoop } from "react-icons/md";
import "./style.css";

export const ControlRepeat = ( { className="", fillColor, isRepeating, toggleRepeat } ) => {  
  return (
    <>    

    <div className={`playback-loop-wrapper  ${className}`}>
      <button className={`playback-loop ${className}`} onClick={toggleRepeat} title="Repeat">
        <MdLoop color={isRepeating ? fillColor : "var(--gray-500)"} />
        <span className="tooltip">Repeat</span>
      </button>
    </div>
    </>
  );
};

