import React from "react";
import { NOTE_MODE_OPENED } from '../../constants.js';
import { SpeakerAnimate } from "../SpeakerAnimate";
import { AppData } from "../../components/AppContext";
import { PlayListData } from "../../components/AudioPlayListContext";
import { addToPlayList } from "../../utils/playlistFunctions.js";
import { setAudioPassage } from "../../utils/setAudioPassage.js";
import { trackCardStart } from "../../utils/analytics.js";
import "./style.css";

export const AudioNoteCard = ( {    
    className="audio-note-card-instance",
    cardId,
    cardType="note",
    cardCategory=null,
    cardCategoryId=null,
    cardImage=process.env.REACT_APP_PLACEHOLDER_IMAGE,
    cardColor,
    cardAudioTitle = "",
    cardViewMode="mini",
    cardName = "",
    cardIndex=null,
    cardParent=null,  //name of the movement or chapter
    cardSection=null,
    cardSectionId=null,
    cardDuration="",
    cardPassageAudio=[],
    cardPassage=null,
    cardPassageFileName=null,
    playAudioOnClick=false,
    setCurrentCardAction=null
}) => {
  
  const {  storyMode,currentBook,setCurrentTrack, trackIndex, setTrackIndex, setCurrentSection, setAudioLoading, setIsOpenControls, enableControls} = AppData();

  const clickActions = () => {
    const setCurrentCard = setCurrentCardAction?setCurrentCardAction():null;

    if (setCurrentCard) {
      (cardIndex !== null)?setCurrentCard(cardIndex):setCurrentCard(cardName);
    }
    if (playAudioOnClick ) {
      if (cardPassageAudio) {
        playActions();
      }      
    }    
  }

  const { dispatch, captureTrackEnd, startTime } = PlayListData();

  /**
   * 
   * @param {*} event 
   * 
   * Adds the current card to the playlist, then sets the audio passage to begin playing
   * Since the user is clicking directly on the note card, add it to the playlist regardless of its category type
   * Do not add empty audio files sometimes used as placeholders (duration 0)
   */
  const playActions = async () => {
    // console.log("playActions - cardDuration: " , cardDuration);
    // Some notes have duration set to 0 incorrectly in the API, so do not filter them out
    // if (cardDuration && cardDuration !== "" && cardDuration !== "0" && cardDuration !== "00:00" && cardDuration !== "--:--") {
      let {nextIndex} = await addToPlayList(
        {
          cardId:cardId,
          cardType:cardType,
          cardMode:storyMode,
          cardBook:currentBook,
          cardParent:cardParent,
          cardSection:cardSection,
          cardName:cardName,
          cardPassageAudio:cardPassageAudio,
          cardPassageFileName:cardPassageFileName,
          cardPassage:cardPassage,
          cardCategory:cardCategory,
          cardCategoryId:cardCategoryId,
          cardDuration:cardDuration,
          cardImage:cardImage,
          cardAudioTitle:cardAudioTitle,
          cardColor:cardColor,
          showCard:true,  // Always add since this was a manual user interaction
          overrideDelete:true, // manual user interaction, do not auto-delete note based on active categories
          cardNotes:null, // no notes for audio cards
          dispatch:dispatch
      });
      // console.log("AudioNoteCard - captureTrackEnd startTime: ", startTime);
      captureTrackEnd("track-change", trackIndex, startTime);
      trackCardStart("click", currentBook, storyMode, cardType, cardCategory, cardParent, cardSection, cardName);
      setAudioPassage(cardPassageAudio,nextIndex,cardSection,setCurrentTrack,setTrackIndex,setCurrentSection,setAudioLoading);
      enableControls.current = true;
      setIsOpenControls(true);
  }

  let cardStyle = {};
  if (cardColor) {
    // Append opacity to the color - must use 2-digit hex value to append to the #RRGGBB value: 75% = 0xBF; 80%=0xCC
    // The alpha value AA is a hex representation of 0-100 where 0 = 00 and 100 = FF.   round(AA / 100) * 255 - then convert to hex
    let color = cardColor + 'BF'      
    cardStyle = { borderColor: color, borderStyle: "solid" }
  }

  return (
    <div className={`audio-note-card card-mode-${cardViewMode}`}>
      <div className="frame-card" onClick={clickActions}>
        {/* <div className="frame-background"> */}
          <div className="frame-overlay">
            <div className="frame-image" style={cardStyle} >
              <img className={`audio-note-image ${cardViewMode}`} alt="audio note" src={cardImage} />
            </div>
            { cardViewMode === NOTE_MODE_OPENED && 
              <div className="frame-title">
                <div className="card-name">{cardName}</div>
                <SpeakerAnimate className='title-icon-img' cardAudioTitle={cardAudioTitle} cardName={cardName}/>
              </div>
            }
          </div>
        {/* </div> */}
      </div>
    </div>
  );
};
