import React, { useState, useRef } from "react";
import { RxDownload } from "react-icons/rx";
import { DownloadListModal } from "../DownloadListModal";
import "./style.css";
/**
 * DownloadList - This component will be rendered from various levels of ElementCards, based on the cardType property:
 * 
 * section : downloads the section and its related notes
 * 
 * Invoke more queries to fetch sections to download via DownloadListModal: 
 * chapter: pops up a processing modal, queries all sections for the chapter, and adds the sections and notes to the downloader.
 * movement: pops up a processing modal, queries all sections for the movement, and adds the sections and notes to the downloader.
 * book: pops up a processing modal, queries all movements for the book, with sections=true, and adds the ections and notes to the downloader.
 * 
 */
export const DownloadList = ( {
    className,
    bookType=null,
    cardId=null,
    cardType=null,
    cardMode=null,
    cardBook=null,
    cardParent=null,
    cardSection=null,
    cardName=null,
    downloadAction=null
    } ) => {
  // console.log("DownloadList cardType:", cardType, "cardId", cardId, "cardMode:", cardMode, "cardBook:", cardBook, "cardParent:", cardParent, "cardSection:", cardSection, "cardName:", cardName);

  const [isOpenDownloadListModal, setIsOpenDownloadListModal] = useState(false);
  const [downloadListComplete, setDownloadListComplete] = useState(false);
  const [downloadListError, setDownloadListError] = useState(false);
  const cardLabel = useRef(cardType);

  const clickActions = async (event) => {    
    if (cardType === "section" || cardType === "note") {
      if (downloadAction) {
        // console.log("Executing downloadAction...", downloadAction);
        await downloadAction()
      } else {
        console.error("No downloadAction defined for cardType:", cardType);
      }
    } else {
      setDownloadListComplete(false);
      setDownloadListError(false);
      setIsOpenDownloadListModal(true);
    }
    event.stopPropagation();
  }

  // Function to pass to modal to execute on close
  function closeDownloadListModal() {
      // console.log("closeDownloadListModal()");
      //Close the dialog 
      setIsOpenDownloadListModal(false);
      // Revert to scrollable page when modal closes
      document.body.style.overflow = "auto";
    }
  

  // console.log("PlayListAdd isOpenDownloadListModal:", isOpenDownloadListModal);

  return (
    <div className={`obt-download-list ${className}`}>
      <div className="obt-download-list-frame" >
          <div className="icon-frame">
            <span className="tooltip">Download {cardLabel.current}</span>
            <RxDownload className="obt-download-list-icon" fill="rgb(235 241 249 / 50%)" onClick={(e)=>clickActions(e)}/>

            {isOpenDownloadListModal && 
            <DownloadListModal
              className="obt-download-list-modal"
              bookType={bookType}
              cardId={cardId}
              cardType={cardType}
              cardMode={cardMode}
              cardBook={cardBook}
              cardParent={cardParent}
              cardSection={cardSection}
              cardName={cardName}
              open={isOpenDownloadListModal && !downloadListComplete}
              isOpenDownloadListModal={isOpenDownloadListModal}
              downloadListComplete={downloadListComplete}
              setDownloadListComplete={setDownloadListComplete}
              downloadListError={downloadListError}
              setDownloadListError={setDownloadListError}
              onClose={() => {closeDownloadListModal()}}/>
            }
          </div>
      </div>
    </div>
  );
}
