import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getModeParm }  from './getModeParm';
import { BOOK_TYPE_EXTRAS } from '../constants.js';
import { getBible, setBooks } from './localSettings';

export const GetAllBooks = ( bookType, isOBTHelps, queryEnabled=true ) => {
    const biblesPath = isOBTHelps?'helps' : 'bibles';
    const extrasPath = process.env.REACT_APP_EXTRAS_PATH?process.env.REACT_APP_EXTRAS_PATH:'bibles';
    let books = [];
    let {isMVP, modeParm} = getModeParm();
    const isExtras = (bookType === BOOK_TYPE_EXTRAS);
    const { bibleId, isAPM, isBibleBrain } = getBible();
    const isEnabled = !!bibleId && queryEnabled // Only run when `bibleId` is truthy and the query is enabled from caller
    
    let url = '';
    // Extras pulls from General projects, otherwise pull Scripture projects from books endpoint
    if (isExtras) {
      if (isMVP) {
        url = `${process.env.REACT_APP_BASE_URL}/bibles/${bibleId}/extras`;
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/${extrasPath}/${bibleId}/general/books${modeParm}`;
      }
    } else {
      if (isBibleBrain) {
        url = `${process.env.REACT_APP_PROXY_URL}/bibles/${bibleId}/books${modeParm}`
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/${biblesPath}/${bibleId}/books${modeParm}`
      }
    }    
    
    // console.log("GetAllBooks: ", url);
    let { data, isPending, isError, error, isFetching, isFetched } = useQuery({
        queryKey: [bibleId,bookType],
        queryFn: () => axiosClient.get(url).then((res) => res.data),
        retry: 2,  // only retry twice before failing
        enabled: isEnabled,
        staleTime: 1000 * 60 * 30, // cache for 30 minutes
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });

    if (!isPending && !isError && (bibleId === undefined || bibleId === null)) {
      // The value "undefined" as bible_id parm does not generate an error, but we want to treat it as a 404
      // Force the 404 here as we can't catch prior to useQuery because useQuery hook cannot be conditionally reached
      let response = {status:404}
      return { books, isPending, isError:true, error:{message:"Not Found", response} }
    }

    // if (isError ) {
    //   if (error.response && [403,404,500].includes(error.response.status)) {
    //     message = "Select a Bible and Book Type from the menu to view the books."
    //   }
    //   return { books, isPending, isError:true, error:{message} }
    // }

    if (!isPending) {
      if (isBibleBrain) {
        if (isExtras) {
          isError = true;
          error = new Error("Extras are not available for this Bible.");
        } else {
          if (data) {
            // console.log("GetAllBooks: ", data);
            books =  data.data;
          }
        }
      } else if (data) {
        // console.log("GetAllBooks: ", data);
        books =  data.data;
        if (!isMVP && books.length > 1 && isAPM) {
          books.sort((a, b) => (a.book_order > b.book_order)?1:-1);
        }
        if (isMVP && books.length > 0) {
          // Assuming a single book for now
          books[0].book_id = "NVP";
          books[0].name_alt = books[0].name;
        }
      }
    }
  if (isFetching || !isFetched) {
    // console.log("GetAllBooks - isPending:" , isPending, "queryEnabled:" , isEnabled, "isFetching:" , isFetching, "isFetched:" , isFetched, "books:" , books);
  }
  if (isError) { 
    console.log("Error in GetAllBooks:", error);
  } else {
    if (!isPending && books.length > 0) {
      setBooks(books,bookType);
    }
  }
  return { books, isPending, isError, error }
}
