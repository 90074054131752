import React, { useRef, useEffect }  from "react";
import { createPortal } from 'react-dom';
import { RxCross1 } from "react-icons/rx";
import { downloadAudio } from "../../utils/downloadFunctions.js";
import { AppData } from "../../components/AppContext";
import { GetAllMovements } from "../../utils/GetAllMovements";
import { GetAllSections } from "../../utils/GetAllSections";
import { pushSection,  pushMovementNote} from "../../utils/listFunctions.js";
import { getBibleId } from '../../utils/localSettings';
import { STORY_MODE, BOOK_TYPE_EXTRAS, BOOK_TYPE_BOOKS } from '../../constants.js';
import "./style.css";

export const DownloadListModal =  ({ 
    className,
    bookType,
    cardId=null,
    cardType=null,
    cardMode=null,
    cardBook=null,
    cardParent=null,
    cardSection=null,
    cardName=null,
    open,
    isOpenDownloadListModal,
    downloadListComplete,
    setDownloadListComplete,
    downloadListError,
    setDownloadListError,
    onClose } ) => {

  // console.log("DownloadListModal : cardId: ", cardId, "cardType: ", cardType, "cardMode: ", cardMode, "cardBook: ", cardBook, "cardParent: ", cardParent, "cardSection: ", cardSection, "cardName: ", cardName, "open: ", open);
  
  const { storyMode, currentResource, currentImage, navigateFrom, isOBTHelps, fileDownLoader} = AppData();
  const bibleId = getBibleId();
  
  const parentCode = cardId;
  const thisBookType = bookType?bookType:(cardType === BOOK_TYPE_EXTRAS?BOOK_TYPE_EXTRAS:BOOK_TYPE_BOOKS);
  const parent = (bookType === BOOK_TYPE_EXTRAS) || (storyMode === STORY_MODE)?"movements":"chapters";

  // Only one of the queries will be executed based on the cardType - pass in the flag 
  const sectionsQuery = useRef(!downloadListComplete && (cardType === "chapter" || cardType === "movement") ? true : false);
  const bookQuery = useRef(!downloadListComplete && (cardType === BOOK_TYPE_BOOKS || cardType === BOOK_TYPE_EXTRAS) ? true : false); 

  // console.log("DownloadListModal : bookQuery: ", bookQuery, "sectionsQuery: ", sectionsQuery);

  let { sourceId: bookId, sections, notes, isPending: isSectionsPending, 
        isError: isSectionsError, error: sectionsError } = GetAllSections(cardBook, parent, parentCode, currentResource, navigateFrom, thisBookType, isOBTHelps, sectionsQuery.current);
  let { movements, isPendingMovements, isErrorMovements, errorMovements } = GetAllMovements(cardBook, cardType, isOBTHelps, "DownloadList", true, bookQuery.current);

  // Temp for testing - comment out the queries above and uncomment the following line to simulate errors, etc.
  // let isSectionsPending = false, sections = [], notes = [], bookId = null, isSectionsError = true, sectionsError = "Testing forced sections error";
  // let isBooksPending = false, books = [], isBooksError = false, booksError = "Testing forced books error";

  /*------------------------------------------------------------------------------
   * useEffect on mount and unmount to set CSS styles
   *     Prevent the main page from scrolling while modal is open
   *     Adjust CSS to pad the scrollbar width when overflow is hidden
   */
  useEffect(() => {
    const openModal = () => {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${scrollbarWidth}px`; // Add padding to compensate for scrollbar
    };
    
    const closeModal = () => {
      document.body.style.overflow = "auto"; // Restore overflow
      document.body.style.paddingRight = ""; // Remove padding
    };

    openModal();

    return () => {
      closeModal();
    };
  }, []);

  
  /**
   * loadNotes
   */
  const loadNotes = async (notes) => {
    if (notes && notes.length > 0) {
      // console.log("Loading notes", notes);
      notes.forEach(card => {
        cardTrackIndex.current++;
        pushMovementNote(card,passageListRef.current,cardTrackIndex.current,bibleId,bookId,thisBookType,parent,parentCode?parentCode:0,currentImage?currentImage:null,noteSectionIndex.current);
        // Sections start with 0 and increment by 0.1 for each card to make them unique within the movement.  
        noteSectionIndex.current = Math.round((noteSectionIndex.current + 0.1) * 10) / 10; // Use math.round to ensure exact decimal increements wihtout rounding errors.
      });
    }
  }
  
  /**
   * loadPassages
   */
  const loadPassages = async (sections) => {
    if (sections && sections.length > 0) {
      // console.log("Loading sections", sections);
      sections.forEach(card => {
        cardTrackIndex.current++;
        pushSection(card,passageListRef.current,notesListRef.current,cardTrackIndex.current,bibleId,bookId,thisBookType,parent,parentCode?parentCode:0,currentImage?currentImage:null,isOBTHelps);
        // console.log("Section passages:", passageListRef.current);
      });
    }
  }   

  const noteSectionIndex = useRef(0);
  const passageListRef = useRef([]);
  const notesListRef = useRef([]);
  const cardTrackIndex = useRef(0);
  const fcnLoadNotes = useRef(loadNotes);
  const fcnLoadPassages = useRef(loadPassages);
  // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  

  /**
   * 
   * @param {*} sectionIndex - Index of the section to download
   */
  const downloadList = async (downloadName) => {
    let filteredList1 = [];
    let filteredList2 = [];
    let filteredList = [];
            
    filteredList1 = passageListRef.current;
    filteredList2 = notesListRef.current;
    filteredList = filteredList1.concat(filteredList2);
    // console.log("downloadList filteredList:", filteredList);

    if (filteredList.length > 0) {
      // console.log("downloadAudio - DownloadListModal");
      await downloadAudio(cardType, fileDownLoader.current, filteredList, bookId, downloadName, cardId, cardParent, cardSection, cardName);
      setDownloadListComplete(true);
    } else {
      setDownloadListError(true);
    }     
  }

  const fcnDownloadList = useRef(downloadList);
  const downloadName = useRef(cardName? cardName: `${cardBook}-${cardType}-${cardId}`);

  /*------------------------------------------------------------------------------
   * MOVEMENTS - load to lists and download to zip file
  */
  useEffect(() => {
    
    // console.log("useEffect - ",
    //   "downloadListComplete", downloadListComplete,
    //   "bookQuery", bookQuery.current,
    //   "isPendingMovements", isPendingMovements, 
    //   "isErrorMovements", isErrorMovements, 
    //   "movements", movements, 
    //   );

    /**
     * loadData() - local function to preload notes and passages 
     */
    const loadData = async () => {
      // console.log("loadData movements ........");
      notesListRef.current = [];
      passageListRef.current = [];
      
      // Load notes and passages for each movement
      for (const movement of movements) {
        await fcnLoadNotes.current(movement.audio_notes);
        await fcnLoadPassages.current(movement.sections);
      }      
      
      // console.log("Calling downloadList - downloadName: ", downloadName.current, "passageList:", passageListRef.current);
      await fcnDownloadList.current(downloadName.current);
    }

    /**
     * Main useEffect Logic
     */
    if  (bookQuery.current && !downloadListComplete && isOpenDownloadListModal 
        && !isPendingMovements && !isErrorMovements && movements ) {
      //)) {
      loadData();
    }
  }, [downloadListComplete, isOpenDownloadListModal, isPendingMovements, isErrorMovements, movements]);

  /*------------------------------------------------------------------------------
   * SECTIONS AND NOTES - load to list and download to zip file
   */
  useEffect(() => {
    
    // console.log("useEffect - ",
    //   "downloadListComplete", downloadListComplete,    
    //   "isSectionsPending", isSectionsPending, 
    //   "isSectionsError", isSectionsError,
    //   "sections:", sections, 
    //   "notes:", notes
    //   );    

    /**
     * loadData() - local function to preload notes and passages
     */
    const loadData = async () => {
      // console.log("loadData sections........");
      notesListRef.current = [];
      passageListRef.current = [];
      await fcnLoadNotes.current(notes);
      await fcnLoadPassages.current(sections);
      
      // console.log("Calling downloadList - downloadName: ", downloadName.current, "passageList:", passageListRef.current);
      await fcnDownloadList.current(downloadName.current);
    }

    /**
     * Main useEffect Logic
     */    
    if ( sectionsQuery.current && !downloadListComplete && isOpenDownloadListModal 
        && !isSectionsPending && !isSectionsError) 
    {
      if ((sections && sections.length > 0) || (notes && notes.length > 0)) {
        loadData();
      } else {
        console.log("No sections or notes to download");
        setDownloadListComplete(true);
      }
    }
    
  }, [downloadListComplete,setDownloadListComplete, isOpenDownloadListModal, isSectionsPending, isSectionsError, sections,notes]);


  /*------------------------------------------------------------------------------
   * useEffect - Close the modal after the download completes.
   */  
  useEffect(() => {
    // console.log("useEffect - downloadListComplete", downloadListComplete);
    if (downloadListComplete) {
        onClose();
    }
  }, [downloadListComplete, onClose]);


  /*------------------------------------------------------------------------------
    RENDER MODAL
  */
  if (!open) return null;

  return createPortal(
    <>
      <div className="download-list-overlay" onClick={(e) => e.stopPropagation() /* Prevent clicks on parent card*/}/>
      <div className={`download-list-modal ${className}`} onClick={(e) => e.stopPropagation() }>
        <div className="close-download-list-modal" >
        { (isSectionsError || downloadListError || isErrorMovements) && <button onClick={onClose}>
          <RxCross1 />
        </button>
        }
        </div>
        <div className="download-list-body">
          <div className="download-list-gradient-frame">
            <div className="header-frame">
              
              {/*  CHAPTER or MOVEMENT */}

                {sectionsQuery.current && (isSectionsError || downloadListError) &&
                  <>
                  <div className="text-wrapper">Unable to download {cardType}</div>
                  <div className="text-wrapper">{sectionsError}</div>
                  </>
                }

                {sectionsQuery.current && !downloadListComplete && !isSectionsError && !downloadListError &&
                  <>
                  <div className="spinner"></div>
                  <div className="text-wrapper">Fetching {cardType} for download...</div>
                  </>
                }

              {/*  BOOK  */}
              
                {bookQuery.current && (isErrorMovements || downloadListError) &&
                  <>
                  <div className="text-wrapper">Unable to download {cardType}</div>
                  <div className="text-wrapper">{errorMovements}</div>
                  </>
                }
                
                {bookQuery.current && !downloadListComplete && !isErrorMovements && !downloadListError &&
                  <>
                  <div className="spinner"></div>
                  <div className="text-wrapper">Fetching {cardType} for download...</div>
                  </>
                }

            </div>
          </div>
        </div>
      </div>
      
    </>,
    document.getElementById("modal-portal")
  ) ;
}
