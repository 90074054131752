/* Constants shared across site */

export const STORY_MODE = "story";
export const TRADITIONAL_MODE = "traditional";
export const BOOK_TYPE_BOOKS = "book";
export const BOOK_TYPE_EXTRAS = "extra";
export const BOOK_TYPE_RESOURCES = "obtresource";
export const VIEWSTATE_SMALL = "small";
export const VIEWSTATE_LARGE = "large";
export const VIEWSTATE_CAROUSEL = "carousel";
export const NOTE_MODE_CLOSED = "mini";
export const NOTE_MODE_OPENED = "small";
export const BREAKPOINT_WIDTH_MOBILE = 600;
export const BREAKPOINT_WIDTH_TABLET = 780;
