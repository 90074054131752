
import React, { useEffect } from "react";
import { ElementCard } from "../ElementCard";
import { DisplayMessage } from "../../components/DisplayMessage/DisplayMessage";
import { AppData } from "../../components/AppContext";
import { getBible } from '../../utils/localSettings';
import { GetAllMovements } from "../../utils/GetAllMovements";
import "./style.css";

const placeholderCards = [  
  {
    movement: 1,
    title: "_"
  },
  {
    movement: 2,
    title: "_"
  }
];

export const updateMovementNames = (movements, setCurrentMovementNames, setCurrentMovementIds, type=null) => {
  if (movements && movements.length > 0) {
    // console.log('Refreshing movement names: ', movements);
    let movementList = [];
    let movementIdList = [];
    // console.log("updateMovementNames - movements:", movements);
    for (let card in movements) {
      if (type === "card") {
        movementList[movements[card].movement] = movements[card].title;
        // Use movement id for new API, movement number for old (MVP) API
        movementIdList[movements[card].movement] = movements[card].id?movements[card].id:movements[card].movement;
        // console.log("type card movementIdList:", movementIdList, "card:", card);
      } else {
        movementList[movements[card].id] = movements[card].title;
        movementIdList[movements[card].id] = movements[card].id;
        // console.log("type NOT card movementIdList:", movementIdList, "card:", card);
      }
    }        
    setCurrentMovementNames(movementList);
    // console.log('movementIdList:', movementIdList);
    setCurrentMovementIds(movementIdList);
  }
}

export const Movements = ({ className }) => {
  const { storyMode, viewState, currentBook, setCurrentMovement, bookType, setCurrentMovementNames, setCurrentMovementIds, currentBookImage, isOBTHelps } = AppData();
  const { isBibleBrain } = getBible();

  const { movements, isPendingMovements, isErrorMovements, errorMovements } = GetAllMovements(currentBook, bookType, isOBTHelps, "MovementsPage", false);

  useEffect(() => {
    if (!isPendingMovements) {
      updateMovementNames(movements, setCurrentMovementNames, setCurrentMovementIds);
    }
          
  },[isPendingMovements, movements, setCurrentMovementNames, setCurrentMovementIds]);
    
  // Error Handling
  let message = "";
  if (isErrorMovements) {
    let error = errorMovements;
    message = "Error fetching movements.";         
    if (error && error.response && error.response.status && [403,404,500].includes(error.response.status)) {
      message = "Select a Language and Bible from the menu to view the books."
    }
    if (error && error.message) {
      if (error.message.includes("Network Error")) {
        message = "Unable to connect to the server. Please check your internet connection."
      } else {
        message = error.message;
      }
    }
  }
  
  return (
    <div className={`movements ${storyMode} ${viewState} ${className}`}>
      <div className="frame-movements">

        {isErrorMovements && <DisplayMessage message={`${message}`}/>}

        {isPendingMovements && placeholderCards.map(card => {          
          return (
            <span className="skeleton-box" key={card.movement}>
              <ElementCard
                key={`${currentBook}-Movement-${card.movement}`}
                cardType="movement"
                cardName={card.title}
                cardAnimation = "shimmer"
                cardIndex={card.movement}
                cardDuration={null}
                cardImageClassName={`card-image-${viewState}`}
                cardAudioTitle={null}
                cardState={viewState}  
                showPlay={false} 
                showDownload={false}               
                obtDownloadClassName={`card-download-${viewState}`}
                obtQuickPlayClassName={`card-quickplay-${viewState}`}
                overlapGroupClassName="instance-node"
                subheadingClassName="element-card-subheading"
                showAddToPlayList={false}
              />
            </span>
          )
        })}

        {!isPendingMovements && !isErrorMovements && movements && movements.map(card => {          
              return (
              <ElementCard
                key={`${currentBook}-Movement-${card.movement}`}
                cardType="movement"
                cardId={card.id}
                cardName={card.title}
                cardIndex={card.movement}
                cardDuration={card.duration}
                cardChapter={card.chapter_start}
                cardImageClassName={`card-image-${viewState}`}
                cardImage={card.images[0]?card.images[0].url?card.images[0].url:currentBookImage:currentBookImage}
                cardAudioTitle={card.title_audio?card.title_audio[0]?.url?card.title_audio[0].url:null:null}
                cardState={viewState}
                showPlay={false}
                showDownload={isBibleBrain?false:true}
                obtDownloadClassName={`card-download-${viewState}`}
                obtQuickPlayClassName={`card-quickplay-${viewState}`}
                overlapGroupClassName="instance-node"
                subheadingClassName="element-card-subheading" 
                navigateTo="/sections" 
                setCurrentCardAction={() => setCurrentMovement}
                showAddToPlayList={true}
                imageOverlay={card.images[0]?(card.images[0].url?null:" "):" "}
              />
              )
            })}
      </div>      
    </div>
  );
};

