import React, {useRef, useState, useEffect } from 'react';
import useWindowWidth from "../../hooks/useWindowWidth";
import { MdOutlinePlayCircleFilled as Play, MdPauseCircleFilled as Pause, MdForward10 as Forward, MdReplay10 as Rewind } from "react-icons/md";
import { RiSkipForwardMiniFill as Next, RiSkipBackMiniFill as Prev } from "react-icons/ri";
import { PiSpinnerBold as LoadSpinner} from "react-icons/pi";
import { CategoryFilter } from "../../components/CategoryFilter";
import { ControlPlaylist } from "../../components/ControlPlaylist";
import { ControlRepeat } from "../../components/ControlRepeat";
import { setAudioPassage } from "../../utils/setAudioPassage.js";
import { toggleCategory } from "../../utils/categoryFunctions.js";
import { getPlayListCardByIndex, getPlayListNextTrack, savePlaylist } from "../../utils/playlistFunctions.js";
import { getNextSectionId } from "../../utils/listFunctions.js";
import { AppData } from "../../components/AppContext";
import { PlayListData } from "../../components/AudioPlayListContext";
import { PlayListAddModal } from "../PlayListAddModal";
import { getTime } from "../../utils/getTime.js";
import { trackCardStart } from "../../utils/analytics.js";
import { TRADITIONAL_MODE, STORY_MODE, BOOK_TYPE_EXTRAS } from '../../constants.js';
import "./style.css";

export const AudioControls = () => {  

    const { currentBible, setCurrentTrack, setCurrentSection, audioLoading, 
            setAudioLoading, enableControls, isPlaying, setIsPlaying, toggleShowPlayList, 
            showPlayList,  currentTrack, trackIndex, setTrackIndex } = AppData();
    const { playList, playListRef, startTime, setStartTime, updateElapsedTime, captureTrackEnd } = PlayListData();
    const { dataBreakpointsMode } = useWindowWidth();
    const [trackProgress, setTrackProgress] = useState(0);
    const [thisCardName, setThisCardName] = useState('');
    const [thisCardImage, setThisCardImage] = useState(null);
    const [thisCardStyle, setThisCardStyle] = useState({});
    const [thisCardDuration, setThisCardDuration] = useState();
          
    // Refs    
    const intervalRef = useRef();
    const audioRef = useRef(new Audio());
    const playPromise = useRef();
    //const firstPlay = useRef(skipFirstPlay);  // Used to prevent autoplay on first track of new movement or chapter
    const firstPlay = useRef(false);
    const setIsPlayingRef = useRef(setIsPlaying); 
    //const skipFirstPlayRef = useRef(skipFirstPlay); // Used to prevent autoplay on first track    
    const skipFirstPlayRef = useRef(false); // Used to prevent autoplay on first track    
    
    // to prevent dependency warning on useEffect:
    const fcnStartTimer = useRef(); 
    const fcnUpdateTrack = useRef(); 
    const fcnPauseSafely = useRef(); 
    const fcnSetAudioSrc = useRef();
    const fcnSetTrackName = useRef();
    const fcnEnableControl = useRef();
    const localTrack = useRef(currentTrack); 
    const thisSetTrackIndex = useRef(setTrackIndex);
    const thisTrackIndex = useRef(trackIndex);
    const previousTrackIndexRef = useRef(0);
    const isPlayingRef = useRef(isPlaying);
    const nextSection = useRef({});

  const pauseSafely = async (audio) => {
    // Ensure the audio is really loaded and playing before attempting to pause it
    function isReallyPlaying(audio) {
      const isPlayingFlag = audio 
                            && !audio.paused 
                            && audio.readyState > audio.HAVE_CURRENT_DATA
                            //&& !audio.ended 
                            //&& audio.src
                            //&& audio.currentTime > 0 ;
      return isPlayingFlag;
    }

    if (playPromise.current !== undefined) {
      playPromise.current.then(() => {
        // Pause safeily if automatic playback started
        if (isReallyPlaying(audio)) {
          audio.pause();
        }
      })
      .catch(error => {
        // Auto-play was prevented
        // console.log("pauseSafely pause ignored:", error);
      });
    }
  }
  fcnPauseSafely.current = pauseSafely;

  // Test whether there is an audio src set
  const isEmptyAudioSrc = (audioSrc) => {
    // console.log("isEmptyAudioSrc: ", audioSrc);
    if (audioSrc === undefined || audioSrc === null || audioSrc === "" ) {
      return true;
    }
    if (audioSrc.slice(-4) === "null" || audioSrc.slice(-9) === "undefined" ) {
      return true;
    }
    return false;
  }

  // Garbage collection: clear old blob before updating the audio source
  const setAudioSrc = (audioSrc, newSrc) => {
    if (!isEmptyAudioSrc(audioSrc)) {
      URL.revokeObjectURL(audioSrc); 
    }    
    return newSrc;
  }
  fcnSetAudioSrc.current = setAudioSrc;

  const setTrackName = (trackIndex) => {
    // console.log("setTrackName - trackIndex: ", trackIndex);
    savePlaylist(playList);  // save before referencing to make sure last update is in local storage
    // console.log("setTrackName - calling getPlayListCardByIndex for trackIndex: ", trackIndex);
    let thisCard = getPlayListCardByIndex(trackIndex);
    // console.log("setTrackName - thisCard: ", thisCard);
    let cardName = thisCard ? thisCard.cardName ? thisCard.cardName : "" : "" ;
    setThisCardName( cardName );
    let cardDuration = thisCard ? thisCard.cardDuration ? thisCard.cardDuration : null : null ;
    // console.log("setTrackName - cardDuration: ", cardDuration);
    setThisCardDuration( cardDuration );
    let cardImage = thisCard ? thisCard.cardImage ? thisCard.cardImage : null : null;
    // console.log("setTrackName - cardImage: ", cardImage, "thisCard", thisCard);
    setThisCardImage( cardImage );
    let cardColor = thisCard ? thisCard.cardColor ? thisCard.cardColor : null : null;
    if (cardColor) {
      // Append opacity to the color - must use 2-digit hex "alpha" value to append to the #RRGGBB value: 75% = 0xBF; 80%=0xCC
      // The alpha value AA is a hex representation of 0-100 where 0 = 00 and 100 = FF.   round(AA / 100) * 255 - then convert to hex
      let color = cardColor + 'BF' 
      let cardStyle = { borderColor: color, borderStyle: "solid" }
      setThisCardStyle(cardStyle);
    } else {
      setThisCardStyle({});
    }
    // console.log("setTrackName - end");
  }
  fcnSetTrackName.current = setTrackName;

  /**
   * trackIndex: Save the new track name when track index on playlist changes
   */  
  useEffect(() => {
    // console.log("useEffect [trackIndex] - trackIndex: ", trackIndex);
    // Set the track name by calling internal function
    fcnSetTrackName.current(trackIndex);    
  
  }, [trackIndex]);

  /**
   * Local Ref values to track elapsed play time for metrics
   * These mimic the corresponding useState variables without depending on event order between the updating of the elapsed vs start times. 
   * They can also be referenced inside the useEffects without causing unnecessary re-renders.
   */  
  const startTimeRef = useRef(startTime);
  const fcnUpdateElapsedTime = useRef();
  fcnUpdateElapsedTime.current = updateElapsedTime;
  
  /**
   * isPlaying:  play/pause audio
   */
  useEffect(() => {
    // console.log("useEffect [isPlaying]", audioRef.current, "isPlaying: ", isPlaying);
    isPlayingRef.current = isPlaying;
    try {
      if (audioRef.current) {
        if (isPlaying) {
          
          if (isEmptyAudioSrc(audioRef.current.src)) {
            // Start with the first track if there isn't any audio source set yet
            fcnUpdateTrack.current(1);
          } else {
            firstPlay.current = false;
            // console.log("[isPlaying] - firstPlay false");
            playPromise.current = audioRef.current.play();
            if (playPromise.current !== undefined) { 
              // On older browsers play() does not return anything, so the value would be undefined.
              playPromise.current
                .then(() => {
                  // Audio is playing
                  fcnStartTimer.current();
                })
                .catch(error => {
                  console.error("isPlaying play error: ", error);
                });
            } else {
              console.error("useEffect isPlaying src is not defined");
            }
          }
        } else {        
          clearInterval(intervalRef.current);
          fcnPauseSafely.current(audioRef.current);
          // Calculate new elapsed time every time audio stops playing
          // console.log("isPlaying - startTimeRef.current:", startTimeRef.current);
          if (startTimeRef.current) {
            // console.log("useEffect NOT isPlaying - fcnUpdateElapsedTime startTimeRef.current", startTimeRef.current)
            fcnUpdateElapsedTime.current(startTimeRef.current);
            startTimeRef.current = null;
          }
        }
      }
    } catch (error) {
      console.error(`useEffect isPlaying error: ${error}`);
    }
  }, [isPlaying]);

  /**
   * currentTrack, playListRef, enableControls: Handle setup when changing tracks
   */
  useEffect(() => {
    // console.log("useEffect [currentTrack, playListRef]", currentTrack);
    try {
      localTrack.current = currentTrack;
      if (audioRef) {
        fcnPauseSafely.current(audioRef.current);
        if (currentTrack) {
          // console.log("scrollIntoViewIfNeeded",playListRef.current, currentTrack)
          playListRef.current?.scrollIntoViewIfNeeded({behavior: 'smooth', block: 'end', inline: 'nearest',});
          audioRef.current.crossOrigin = 'anonymous';
          //audioRef.current.src = currentTrack;
          audioRef.current.src = fcnSetAudioSrc.current(audioRef.current.src,currentTrack);
          setTrackProgress(audioRef.current.currentTime);          
          // Do not autoPlay on first track of a new movement/chapter
          if (firstPlay.current === false) {
            // console.log("[currentTrack] firstPlay.current",firstPlay.current);
            if (!enableControls.current) enableControls.current = true;
            playPromise.current = audioRef.current.play();
            if (playPromise.current !== undefined) { 
              // On older browsers play() does not return anything, so the value would be undefined.
              playPromise.current
                .then(() => {
                  // Audio is playing.
                })
                .catch(error => {
                  console.error("currentTrack play error: ", error);
                });
            }
            if (isPlayingRef.current) {
              // if already playing, reset the timer since the isplaying useeffect will not trigger
              fcnStartTimer.current();
            } else {
              setIsPlayingRef.current(true);
            }
            
          } else {
            // After the first track change we can let it autoplay
            firstPlay.current = false;
            // console.log("[currentTrack] - Set firstPlay false")
          }
          
        }
      }
    } catch (error) {
      console.error("useEffect currentTrack error: ", error);
    }
  }, [currentTrack, playListRef, enableControls]);

  

  const setEnableControls = (value) => {
    enableControls.current = value;
  }
  fcnEnableControl.current = setEnableControls;

  /**
   * storyMode, currentMovement, currentChapter: 
   * Whenever a new movement or chapter is selected, reset to clear out any old track
   */
  useEffect(() => {
    // console.log("useEffect [currentBible] - resetting controls");    
    try {
      if (audioRef) {
        // console.log("useEffect [currentBible] - audioRef:", audioRef.current);
        const ref = audioRef.current;
        fcnPauseSafely.current(ref);
        if (audioRef.current && !isEmptyAudioSrc(audioRef.current.src)) {
          // console.log("useEffect [currentBible] - resetting audioRef.current.src", audioRef.current.src);
          audioRef.current.src = fcnSetAudioSrc.current("");
        }
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      fcnEnableControl.current(false);
      setIsPlayingRef.current(false);
      setThisCardName("");
      setThisCardDuration("");
      setThisCardImage(null);
      firstPlay.current = true;
      thisSetTrackIndex.current(0);
      fcnUpdateTrack.current(0);
    } catch (error) {
      console.error("useEffect error: ", error);
    }
  }, [currentBible]);
  //}, [storyMode, currentMovement, currentChapter]);

  /**
   * On Mount : Set initial states
   * On Unmount: clean up any timers, pause audio, and reset any states
   */
  useEffect(() => {
    // console.log("AudioControls mount");
    const ref = audioRef?.current;
    const isplayingRef = setIsPlayingRef?.current;    
    fcnSetTrackName.current(thisTrackIndex.current);
    // console.log("AudioControls mount - Setting firstPlay.current = ", skipFirstPlayRef.current)
    firstPlay.current = skipFirstPlayRef.current;
    // Pause and clean up on unmount
    return () => {      
      try {                
        // console.log("AudioControls Unmount");
        if (audioRef) {
          // console.log(`AudioControls unmount pausing play...`);
          fcnPauseSafely.current(ref);
          isplayingRef(false);
          clearInterval(intervalRef.current);
        // } else {
        //   firstPlay.current = false;
        } 
      } catch (error) {
        console.error(`AudioControls unmount error: ${error}`);
      }
    }
  }, []);

  /**
   * Repeat loop controls - when audio reaches the end, either loop or go to the next track
   */
  const [isRepeating, setIsRepeating] = useState(false);
  const toggleRepeat = () => {
    setIsRepeating((prev) => !prev);
    if (audioRef.current) {
      audioRef.current.loop = !audioRef.current.loop;
    }
  };
 
  // Constants for tracking play progress - to update the background style of the range input
  const { duration } = audioRef.current;  
  const currentPercentage = duration ? `${(trackProgress / duration) * 100}%` : '0%';
  const emptyColor = "var(--gray-300)";
  const fillColor = "var(--primary-500)";
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, ${fillColor}), color-stop(${currentPercentage}, ${emptyColor}))
  `;
  const isAudioLoading = audioLoading ? "loading" : "";
  // console.log("isAudioLoading: ", isAudioLoading);

  /**
   * Reset the start time for calculating elapsed time for metrics
   */
  const newStartTime = () => {
    const now = Date.now();
    startTimeRef.current = now;
    setStartTime(now);
    // console.log("newStartTime", now);
  }

  /**
   * Start the progress bar timer during playback
   */
  const startTimer = () => {
    try {
      // Clear any timers already running
      clearInterval(intervalRef.current);
      
      intervalRef.current = setInterval(() => {
        if (audioRef.current.ended) {
          clearInterval(intervalRef.current);
          previousTrackIndexRef.current = trackIndex;
          let hasTrack = toNextTrack();
          if (!hasTrack ) {
            // If no next track, stop playback
            // Save metrics for the final track that played since it will not be triggered in the useEffact for trackIndex change
            // console.log("startTimer setInterval - captureTrackEnd startTimeRef.current: ", startTimeRef.current);
            captureTrackEnd("playlist-end",previousTrackIndexRef.current,startTimeRef.current);
            startTimeRef.current = null;
            fcnPauseSafely.current(audioRef.current);
            setIsPlayingRef.current(false);
          }
        } else {
          setTrackProgress(audioRef.current.currentTime);
        }
      }, [1000]);

      newStartTime();

    } catch (error) {
      console.error(`startTimer error: ${error}`);
    }
  }
  // useRef for function reference eliminates dependency in useEffects
  fcnStartTimer.current = startTimer; 

  /**
   * 
   * @param {*} thisTrackIndex 
   */
  const updateTrack = (thisTrackIndex,fromAction) => {
    function indexMatch(card) {
      // card is the array element being filtered - implicitly passed by filter function
      return (parseInt(card.cardIndex) === parseInt(thisTrackIndex));
    }
    // function idMatch(card) {
    //   // card is the array element being filtered - implicitly passed by filter function
    //   return (parseInt(card.id) === parseInt(thisTrackId));
    // }
    
    try {    
      // console.log("updateTrack playList:",playList)
      let newTrack = playList.filter(indexMatch);
      if (newTrack.length > 0) {
        // console.log("newTrack",newTrack)
        // console.log("updateTrack - captureTrackEnd startTimeRef.current: ", startTimeRef.current);
        captureTrackEnd("track-change", previousTrackIndexRef.current, startTimeRef.current);
        trackCardStart(fromAction, newTrack[0].cardBook, newTrack[0].cardMode, newTrack[0].cardType, newTrack[0].cardCategory, newTrack[0].cardParent, newTrack[0].cardSection, newTrack[0].cardName);
        // Set the audio passage to start playing
        setAudioPassage(newTrack[0].cardPassageAudio, newTrack[0].cardIndex, newTrack[0].cardSection,setCurrentTrack,setTrackIndex,setCurrentSection, setAudioLoading);
        // .then(url => {
        //   console.log("AudioControls passagePromise url", url);
        // })
      } else {
        // console.log(`updateTrack - no new track found for trackIndex = ${thisTrackIndex}`);
      }   
    } catch (error) {
      console.error(`updateTrack error: ${error}`);
    }
  }
  // useRef for function reference eliminates dependency in useEffects
  fcnUpdateTrack.current = updateTrack;

  /**
   * Advance to the previous track in the playlist.
   * If the current track is the first in the playlist, no previous track is played.
   */
  const toPrevTrack = () => {
    try {
      let prevTrackIndex = trackIndex;
      let thisTrackIndex = trackIndex;
      // console.log('toPrevTrack - trackIndex:', trackIndex);
      if (trackIndex - 1 < 1) {
        // If already at first track, remain there, nothing new to play
        console.log("Beginning of playlist has been reached.  No previous track to play.");
      } else {
        thisTrackIndex = trackIndex - 1;
        // Skip hidden tracks (disabled audio note categories)
        let found = false;
        let showCard = false;
        while (!found) {
          let card = getPlayListCardByIndex(thisTrackIndex);
          showCard = card ? card.cardShow ? card.cardShow : false : false;
          if (showCard) {
            found = true;
          } else {
            thisTrackIndex--;
            if (thisTrackIndex === trackIndex) {
              // back where we started so stop
              found = true;
            }
            if (thisTrackIndex < 1) {
              // If already at first track, remain there, nothing new to play
              thisTrackIndex = trackIndex;
              found = true;
              console.log("Beginning of playlist has been reached.  No previous track to play.");
            }
          }
        }
      }
      if (prevTrackIndex !== thisTrackIndex) {
        previousTrackIndexRef.current = prevTrackIndex;
        setTrackIndex(thisTrackIndex);
        updateTrack(thisTrackIndex,"toPrevTrack");
      } 
    } catch (error) {
      console.error(`toPrevTrack error: ${error}`);
    } 
  }
  
  const [isOpenPlayListModal, setIsOpenPlayListModal] = useState(false);
  const [playListAddComplete, setPlayListAddComplete] = useState(false);
  const [playListAddError, setPlayListAddError] = useState(false);
  const [playIndex, setPlayIndex] = useState(null);
  const updatePlayIndex = (playIndex) => { setPlayIndex(playIndex) };

  // Function to pass to modal to execute on close
  function closePlayListModal() {
    // console.log("closePlayListModal()");
    //Close the dialog 
    setIsOpenPlayListModal(false);
    // Revert to scrollable page when modal closes
    document.body.style.overflow = "auto";
  }

  const fcnSetPlayTrack = useRef();
  const setPlayTrack = () => {
    previousTrackIndexRef.current = trackIndex;
      setTrackIndex(playIndex);
      updateTrack(playIndex,"toNextTrack");
  }
  fcnSetPlayTrack.current = setPlayTrack;

  useEffect(() => {
    if (playListAddComplete) {
      // More tracks were just loaded, so update the track index and play the new track      
      // console.log("playListAddComplete - playIndex: ", playIndex); 
      // previousTrackIndexRef.current = trackIndex;
      // setTrackIndex(playIndex);
      // updateTrack(playIndex,"toNextTrack");
      fcnSetPlayTrack.current();
      setPlayListAddComplete(false);  // reset the state
    }
  }, [playListAddComplete, setPlayListAddComplete]);

  /**
   * Advance to the next track in the playlist.
   * If the current track is the last in the playlist, find the next section that follows the current track.
   * This may require navigation to the next chapter, movement, or book or movement.
   */
  const toNextTrack = () => {
    try {
      let prevTrackIndex = trackIndex;
      let thisTrackIndex = trackIndex;
      // console.log('toNextTrack - trackIndex:', trackIndex);
      // console.log("playList:", playList);
      thisTrackIndex = getPlayListNextTrack(trackIndex);
      if (thisTrackIndex === null) {      
        // If at the end of the playlist, find the next section that follows the current track
        // This may be in the next chapter, movement, or book
        const card = getPlayListCardByIndex(trackIndex);
        if (card) {
          const thisSectionId = card.cardSectionId;
          const bookType = card.cardBookType;
          // console.log("thisSectionId:", thisSectionId, "bookType", bookType);
          const response = getNextSectionId(bookType, thisSectionId);
          // console.log("getNextSectionId response:", response);
          if (response) {
            // Set reference values to use for component rendering to add this next section to the playlist
            nextSection.current.sectionId = response.sectionId;
            nextSection.current.book = response.book;
            nextSection.current.chapterId = response.chapterId;
            nextSection.current.type="chapter";
            nextSection.current.mode=TRADITIONAL_MODE;
            nextSection.current.useNextPlayIndex=false;
            nextSection.current.bookType = bookType;
            setIsOpenPlayListModal(true);
          } else {
            if (bookType === BOOK_TYPE_EXTRAS) {
              // Extras may not have sections, so will have to get all movements from the modal and match against the audio notes
              nextSection.current.sectionId = thisSectionId;
              nextSection.current.book = card.cardBook;
              nextSection.current.chapterId = null;
              nextSection.current.type=BOOK_TYPE_EXTRAS;
              nextSection.current.mode=STORY_MODE;
              nextSection.current.useNextPlayIndex=true;
              nextSection.current.bookType = bookType;
              setIsOpenPlayListModal(true);
            } else {
              console.log("No next section found for cardId:", card.cardId);
            }
          }
        } else {
          console.log("No next section found");
        }
      }

      if (thisTrackIndex && prevTrackIndex !== thisTrackIndex) {
        previousTrackIndexRef.current = prevTrackIndex;
        setTrackIndex(thisTrackIndex);
        updateTrack(thisTrackIndex,"toNextTrack");
        return true;
      } else {
        return false;
      }

    } catch (error) {
      console.error(`toNextTrack error: ${error}`);
    }
  }
  
  const rewind = () => {
    try {
      const changeTime = 10;   // 10 seconds in milliseconds
      let thisTime = audioRef.current.currentTime;
      // console.log("thisTime:", thisTime);
      if (thisTime > 0) {
        let timeDiff = Math.min(thisTime,(thisTime-changeTime));  
        // console.log("timeDiff:", timeDiff);
        audioRef.current.currentTime -= (thisTime - timeDiff);
        // console.log(audioRef.current.currentTime);
        if (isPlaying) {
          // console.log("rewind - fcnUpdateElapsedTime startTimeRef.current", startTimeRef.current)
          fcnUpdateElapsedTime.current(startTimeRef.current);
          startTimeRef.current = null;
        }
        newStartTime();
        setTrackProgress(audioRef.current.currentTime);
      }
    } catch (error) {
      console.error(`rewind error: ${error}`);
    } 
  }
  
  const fastForward = () => {
    try {
      const changeTime = 10;   // 10 seconds
      let thisTime = audioRef.current.currentTime;
      let remainingTime = audioRef.current.duration - thisTime;
      // console.log("thisTime:", thisTime);
      if (remainingTime > 0) {
        let timeDiff = Math.min(remainingTime,changeTime);
        // console.log("timeDiff:", timeDiff);
        audioRef.current.currentTime += timeDiff;
        // console.log(audioRef.current.currentTime);
        if (isPlaying) {
          // console.log("fastForward - fcnUpdateElapsedTime startTimeRef.current", startTimeRef.current)
          fcnUpdateElapsedTime.current(startTimeRef.current);
          startTimeRef.current = null;
        }
        newStartTime();
        setTrackProgress(audioRef.current.currentTime);
      }
    } catch (error) {
      console.error(`fastForward error: ${error}`);
    }
  }

  const onScrub = (value) => {
    try {
      // Clear any timers already running
      clearInterval(intervalRef.current);
      audioRef.current.currentTime = value;
      if (isPlaying) {
        // console.log("onScrub - fcnUpdateElapsedTime startTimeRef.current", startTimeRef.current)
        fcnUpdateElapsedTime.current(startTimeRef.current);
        startTimeRef.current = null;
      }
      newStartTime();
      setTrackProgress(audioRef.current.currentTime);
    } catch (error) {
      console.error(`onScrub error: ${error}`);
    }
  }

  const onScrubEnd = () => {
    try {
      // If not already playing, start
      if (!isPlaying) {
        //newStartTime();
        setIsPlayingRef.current(true);
      } else {
        // just reset the time
        fcnStartTimer.current();
      }
    } catch (error) {
      console.error(`onScrubEnd error: ${error}`);
    }
  }
  
  const isEnabled = enableControls.current;
  let controlColor = isEnabled?fillColor:emptyColor;  
  
  //============================================================================
  // MAIN SECTION - Render the control bar
  //============================================================================
  return(
    
    <div className={`audio-controls-container ${dataBreakpointsMode}`} data-breakpoints-mode={dataBreakpointsMode}>

      <div className={`audio-controls-left ${isEnabled? "active" : "inactive"}`}>

        {!isOpenPlayListModal && <>
          <div className="frame-image" style={thisCardStyle} >
              {thisCardImage && <img className={`card-image`} src={thisCardImage} alt="audio card" />}          
          </div>
          <span className="title-text">{isEnabled ? thisCardName : ""}</span>
          </>
        }

        {isOpenPlayListModal &&
            <PlayListAddModal
              className="playlist-add-modal"
              cardId={nextSection.current.chapterId}
              cardBookType={nextSection.current.bookType}
              cardType={nextSection.current.type}
              cardMode={nextSection.current.mode}
              cardBook={nextSection.current.book}
              cardParent={null}
              cardSection={null}
              cardSectionId={nextSection.current.sectionId}
              cardName={null}
              open={isOpenPlayListModal && !playListAddComplete}
              isOpenPlayListModal={isOpenPlayListModal}
              playListAddComplete={playListAddComplete}
              setPlayListAddComplete={setPlayListAddComplete}
              playListAddError={playListAddError}
              setPlayListAddError={setPlayListAddError}
              setPlayIndex={updatePlayIndex}
              useNextPlayIndex={nextSection.current.useNextPlayIndex}
              replaceAtEnd={true}
              onClose={() => {closePlayListModal()}}/>
        }

      </div>

      <div className={`audio-controls-center ${isEnabled ? "active" : "inactive"}`}>

        <div className={`audio-controls`}>
            <button
              type="button"
              className="prev"
              aria-label="Previous"
              onClick={!isEnabled ? null :  toPrevTrack}
            >
              <Prev fill={controlColor}/>
            </button>
            <button
              type="button"
              className="rewind"
              aria-label="Rewind"
              onClick={!isEnabled ? null : rewind}
            >
              <Rewind fill={controlColor}/>
            </button>
            {isPlaying ? (
              <button
                type="button"
                className="pause"
                onClick={!isEnabled ? null : () => setIsPlayingRef.current(false)}
                aria-label="Pause"
              >
                {isAudioLoading ? (
                  <LoadSpinner fill={controlColor} className={`pause-play-icon ${isAudioLoading}`}/>
                ) : (
                  <Pause fill={controlColor} className={`pause-play-icon ${isAudioLoading}`}/>                
                )}
              </button>
            ) : (
              <button
                type="button"
                className="play"
                onClick={!isEnabled ? null : () => {firstPlay.current = false; setIsPlayingRef.current(true)}}
                aria-label="Play"
              >
                {isAudioLoading ? (
                  <LoadSpinner fill={controlColor} className={`pause-play-icon ${isAudioLoading}`}/>
                ) : (
                  <Play fill={controlColor} className={`pause-play-icon ${isAudioLoading}`}/>
                )}
              </button>
            )}
            <button
              type="button"
              className="forward"
              aria-label="Forward"
              onClick={!isEnabled ? null : fastForward}
            >
              <Forward fill={controlColor}/>
            </button>
            <button
              type="button"
              className="next"
              aria-label="Next"
              onClick={!isEnabled ? null : toNextTrack}
            >
              <Next fill={controlColor}/>
            </button>
        </div>

        <div className={`time-controls ${isEnabled ? "active" : "inactive"}`}>
          <span className="time-text">{!isEnabled ? "-:--" : getTime(audioRef.current.currentTime)}</span>
          {/* Progress Bar */}
          <input
            type="range"
            value={trackProgress}
            step="1"
            min="0"
            max={duration ? duration : "0"}
            className={!isEnabled ? "progress-disabled":"progress"}
            onChange={(e) => onScrub(e.target.value)}
            onMouseUp={onScrubEnd}
            onKeyUp={onScrubEnd}
            readOnly={!isEnabled }
            style={{ "background": trackStyling }}
          />
          <span className="time-text">{!isEnabled ? "-:--" : audioRef.current.duration?(!isNaN(audioRef.current.duration) && audioRef.current.duration>0?getTime(audioRef.current.duration):thisCardDuration):thisCardDuration}</span>
        </div>

      </div>

      <div className={`audio-controls-right `}>

        <ControlRepeat isRepeating={isRepeating} fillColor={fillColor} toggleRepeat={toggleRepeat}/>
        
        <CategoryFilter toggleCategory={toggleCategory}/>

        <ControlPlaylist fillColor={fillColor} showPlayList={showPlayList} toggleShowPlayList={toggleShowPlayList}/>

      </div>
    </div>

  );
}
