import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getModeParm }  from './getModeParm';
import { BOOK_TYPE_EXTRAS } from '../constants.js';
import { getBible } from './localSettings';

export const GetAllMovements = ( bookId, bookType, isOBTHelps, parent, withSections=false, queryEnabled=true ) => {
    // console.log("GetAllMovements - queryEnabled: ", queryEnabled, "bookId:", bookId, "bookType:", bookType, "isOBTHelps:", isOBTHelps, "parent:", parent, "withSections:", withSections);
    // The 'parent' parameter is used to uniquely distinguish between different calling components to create unique query keys.
    const biblesPath = isOBTHelps?'helps' : 'bibles';
    const extrasPath = process.env.REACT_APP_EXTRAS_PATH?process.env.REACT_APP_EXTRAS_PATH:'bibles';
    let movements = [];
    let movementNotes = [];
    let {isMVP, modeParm} = getModeParm();
    const isExtras = (bookType === BOOK_TYPE_EXTRAS);
    const { bibleId, isBibleBrain } = getBible();
    const sectionsParm = withSections? "true" : "false";
    let thisParm = "";
    
    if (modeParm !== "") {
      thisParm = withSections ? "" : "&sections=false"
      thisParm = `${modeParm}${thisParm}`
    } else {
      thisParm = withSections? "" : "?sections=false"
    }
    // console.log("thisParm:", thisParm);
    let url = '';
    const isEnabled = queryEnabled && !!bibleId && !!bookId; // Only run when URL parms are truthy
    
    if (isExtras) {
      url = `${process.env.REACT_APP_BASE_URL}/${extrasPath}/${bibleId}/general/${bookId}/movements${thisParm}`
    } else {
      if (isBibleBrain) {
        // url = `${process.env.REACT_APP_PROXY_URL}/movements/${bibleId}/${bookId}`
        url = `${process.env.REACT_APP_PROXY_URL}/bibles/${bibleId}/${bookId}/movements${thisParm}`
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/${biblesPath}/${bibleId}/${bookId}/movements${thisParm}`
      }
      
    }
    // console.log("GetAllMovements", url);
    // Include sectionsParm in the queryKey to allow for the same query with different sections settings
    let { data, isPending, isError, error } = useQuery({
        queryKey: [bibleId,bookId,parent,sectionsParm,"movements"],
        queryFn: () => axiosClient.get(url).then((res) => res.data),
        enabled: isEnabled,
        staleTime: 1000 * 60 * 30, // cache for 30 minutes
        refetchOnMount: false,
        refetchOnWindowFocus: false
    }
  );

  // console.log("URL: ", url );

  if (isError && isMVP && isExtras) {
    console.log("overriding MVP error")
    return { movements, isPendingMovements:false, isErrorMovements:false, error:null }
  }

  if (!isPending) {
    if (data && data.data) {
      if (isMVP ) {
        if (data.data[0] && data.data[0].movements) {
          movements = data.data[0].movements;
        }
      } else {
        if (data.data.movements) {
          movements = data.data.movements;
          // console.log("movements: ", movements)
          if (movements.length > 1) {
            movements.sort((a, b) => a.movement - b.movement);
            
          }
          if (movements.length > 0) {
            // Some movements have audio notes without a section - these are movement level notes and have to be treated differently
            movements.forEach(movement => {
              // console.log("GetAllMovements: movement.audio_notes:",movement.audio_notes)
              movementNotes[movement.id] = movement.audio_notes;
              // console.log("movementNotes[movement.id]:",movementNotes[movement.id])
              if (movementNotes[movement.id] && movementNotes[movement.id].length > 1) {
                movementNotes[movement.id].sort((a, b) => a.passage - b.passage);
              }
            })
          }
        }  
      }
    // } else {
    //   isError = true;
    //   error = new Error("No data available for this request.");
    }
  }
  let isPendingMovements = isPending;
  let isErrorMovements = isError;
  let errorMovements = error;
  // console.log('GetAllMovements - ', 'isPendingMovements', isPendingMovements, 'movements', movements);
  return { movements, movementNotes, isPendingMovements, isErrorMovements, errorMovements }
}
