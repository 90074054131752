import { segmentAnalytics } from "../components/AppContext";
import { getBibleId } from "./localSettings.js";

/**
 * Captures page views in Twilio Segment
 *
 * @param {string} pageName - The name of the page being viewed.
 * @param {string} mode - The mode of the page (Story or Traditional).
 * @param {string} book - The book being viewed, e.g., JON, LUK.
 * @param {string} parent - The parent context, e.g., movement-1, chapter-2.
 * @param {string} parentName - The name of the parent context, e.g., "The King's Birth".
 */
export const trackPage = (pageName, mode, book, parent, parentName) => {
  const userAgent = window.navigator.userAgent;

  // console.log("trackPage", pageName, {
  //   userAgent: userAgent,
  //   bible:getBibleId(),
  //   book:book??"",
  //   mode:mode??"",
  //   parent:parent??"",
  //   parentName:parentName??""
  // });

  segmentAnalytics.page(pageName, {
    userAgent: userAgent,
    bible:getBibleId(),
    book:book??"",
    mode:mode??"",
    parent:parent??"",
    parentName:parentName??""
  });
}

/**
 * Captures the start of audio playback as the user interacts with a card or the audio controls
 *
 * @param {string} action - The action performed:
 *   - `click` - User clicks on a section card, audio note card, or playlist card to begin playback.
 *   - `toNextTrack` or `toPrevTrack` - Navigation via audio controls, manually or auto playback.
 * @param {string} book - The book associated with the action. 
 * @param {string} mode - The mode of the interaction (Story or Traditional). 
 * @param {string} type - The type of card (section or note). 
 * @param {string} category - The category of the audio note where applicable. 
 * @param {string} parent - The parent context.
 * @param {string} section - The section number.
 * @param {string} name - The name of the selected content.
 */
export const trackCardStart = (action, book, mode, type, category, parent, section, name) => {
  
  // console.log("trackCardStart", {
  //   action: action??"",
  //   bible:getBibleId(),
  //   book:book??"",
  //   mode:mode??"",
  //   type:type??"",
  //   category:category??"",
  //   parent:parent??"",
  //   section:section??"",
  //   name:name??""
  // })

  segmentAnalytics.track("Play Start", {
    action: action??"",
    bible:getBibleId(),
    book:book??"",
    mode:mode??"",
    type:type??"",
    category:category??"",
    parent:parent??"",
    section:section??"",
    name:name??""
  });
}

/**
 * Captures the end of playback, with similar details as trackCardStart, along with the duration of playback.
 *
 * @param {string} action - The action performed:
 *   - `playlist-end` - Autoplay of current track has ended
 *   - `track-change` - Track change manually by user interaction
 * @param {string} book - The book associated with the action.
 * @param {string} mode - The mode of the interaction (Story or Traditional).
 * @param {string} type - The type of card (section or note).
 * @param {string} category - The category of the audio note where applicable. 
 * @param {string} parent - The parent context.
 * @param {string} section - The section number.
 * @param {string} name - The name of the selected content.
 * @param {number} duration - The duration of the playback, rounded to two decimal places.
 */
export const trackCardEnd = (action, book, mode, type, category, parent, section, name, duration) => {
  if (duration === undefined || duration === null || duration < 0) {
    duration = 0;
  }
  const roundedDuration = duration.toFixed(2);

  // console.log("trackCardEnd", {
  //   action: action??"",
  //   bible:getBibleId(),
  //   book:book??"",
  //   mode:mode??"",
  //   type:type??"",
  //   category:category??"",
  //   parent:parent??"",
  //   section:section??"",
  //   name:name??"",
  //   duration: roundedDuration??0
  // })

  segmentAnalytics.track("Play End", {
    action: action??"",
    bible:getBibleId(),
    book:book??"",
    mode:mode??"",
    type:type??"",
    category:category??"",
    parent:parent??"",
    section:section??"",
    name:name??"",
    duration: roundedDuration??0
  });
}

/**
 * Captures the request to download content to the device
 *
 * @param {string} action - 'download'
 * @param {string} book - The book associated with the action. 
 * @param {string} mode - The mode of the interaction (Story or Traditional). 
 * @param {string} type - The type of card (book, movement, chapter, section, note)
 * @param {string} id - The id of the object being downloaded
 * @param {string} parent - The parent context.
 * @param {string} section - The section number.
 * @param {string} name - The name of the selected content.
 */
export const trackDownload = (action, book, type, id, parent, section, name) => {
  
  // console.log("trackDownload", {
  //   action: action??"",
  //   bible:getBibleId(),
  //   book:book??"",
  //   type:type??"",
  //   id:id??"",
  //   parent:parent??"",
  //   section:section??"",
  //   name:name??"",
  // })

  segmentAnalytics.track("Download", {
    action: action??"",
    bible:getBibleId(),
    book:book??"",
    type:type??"",
    id:id??"",
    parent:parent??"",
    section:section??"",
    name:name??""
  });
}