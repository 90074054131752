
import React from "react";
import { ObtResourceCard } from "../ObtResourceCard";
import { AppData } from "../../components/AppContext";
import { VIEWSTATE_SMALL, BOOK_TYPE_EXTRAS } from '../../constants.js';
import { GetAllBooks } from "../../utils/GetAllBooks";
import { getBible } from '../../utils/localSettings';
import { DisplayMessage } from "../../components/DisplayMessage/DisplayMessage";
import "./style.css";

// const placeholderCards = [  
//   {
//     book_id: "TMP1",
//     name: "_",
//     name_alt: "_"
//   }
// ];

export const ObtResourcesExtras = ({ 
        className=""
 }) => {

  const { setCurrentBook, isOBTHelps } = AppData();
  let viewState = VIEWSTATE_SMALL;
  let isTraditionalMode = true;
  let queryEnabled = true;
  const { isBibleBrain } = getBible();
  let { books, isPending, isError, error } = GetAllBooks(BOOK_TYPE_EXTRAS, isOBTHelps, queryEnabled);
  
  // Temp for testing loading ...
  // let isPending = true, books = [], isError = false, error = null;
   
  // Error Handling
  let message = "";
  if (isError) {
    let haveError = true;
    message = "Error fetching extras.";         
    if (error && error.response && error.response.status && [403,404,500].includes(error.response.status)) {
      // Reset query if there are no extras available
      queryEnabled = false;
      isError = false;
      isPending = false;
      haveError = false;
    } else if (error && error.message) {
      if (error.message.includes("Network Error")) {
        message = "Unable to connect to the server. Please check your internet connection."
      } else if (error.message.includes("Extras are not available")) {
          haveError = false;
      } else {
        message = error.message;
      }
    }
    if (haveError) {
      return (
        <>
        {isError && <DisplayMessage message={`${message}`}/>}
        </>
      )
    }
  }

  return (
    <>
        {!isPending && !isError && books && books.map(book => {
            return (
            <ObtResourceCard
              key={book.book_id}
              cardCode={book.book_id}
              cardType={BOOK_TYPE_EXTRAS}
              bookType={BOOK_TYPE_EXTRAS}
              cardName={isTraditionalMode ? book.name : book.name_alt}
              cardDuration={book.duration?book.duration:"-:--"}
              cardImageClassName={`resource-image-${viewState}`}
              cardImage={book.images[0]?book.images[0].url?book.images[0].url:null:null}
              cardAudioTitle={isTraditionalMode ? ( book.title_audio?book.title_audio[0]?.url?book.title_audio[0].url:null:null ) 
                                                : ( book.title_audio_alt?book.title_audio_alt[0]?.url?book.title_audio_alt[0].url:null:null ) }
              cardState={viewState}
              showPlay={false}
              obtQuickPlayClassName={`resource-quickplay-${viewState}`}
              showDownload={isBibleBrain?false:true}
              obtDownloadClassName={`resource-download-${viewState}`}
              showAddToPlayList={true}
              setCurrentCardAction={() => setCurrentBook}
              isExtras={true}
            />
            )
          })}
      
    </>
  );
};
