// downloadFunctions.js
import { trackDownload } from "./analytics.js";

/**
 * 
 * @param cardId : id of current resource card
 */
export const downloadAudio = async (type, fileDownloader, list, bookId, downloadName, id, parent, section, name) => {
  // console.log("downloadAudio - type, bookId, downloadName, list:",type, bookId, downloadName, list)
  if (list && list.length > 0) {
    await downloadList(fileDownloader, list, bookId, downloadName);
    // console.log("trackDownload: bookId, type, name?name:downloadName, parent, section", bookId, type, id, name?name:downloadName, parent, section);
    trackDownload("download", bookId, type, id, parent, section, name?name:downloadName);
  }
  
  return;
}

/**
 * 
 * @param {*} fileDownloader : instance of useFileDownloader()
 * @param {*} downloadList : list of files to download in the format built by the Sections and ResourceSecions components
 *          {
 *            "key": unique key for the book
              "id": id for this type of card as returned by the API
              "index": index in the playlist,
              "section": section index in the playlist (may not be used)
              "sectionId": section id
              "title": title / name
              "passageAudio": list of audio files with chapters and titles filtered by selected categories 
              "passageAudioUnfiltered": list of audio files
              "passageFileName": list of audio file names with chapters and titles filtered by selected categories 
              "passageFileNameUnfiltered": list of audio file names
              "image": image file,
              "audioTitle": title audio,
              "passage": if note, this is the passage number
              "category": category name for notes,
              "categoryId": category id for notes,
              "color": color for note category,
              "duration": play duration
              "show":true/false to show this card - used to filter notes by categories
            }
 * 
 */
const downloadList = async (fileDownloader, downloadList, bookId, overrideName=null) => {
    const cleanFileName = (filename) => {
      if (filename)
        return filename.replace(/['"]/g, "");
      else
        return filename;
    }

    // console.log("Downloading list:", bookId, overrideName, downloadList);
    let exportList = [];
    let fileCount = 0; 
    let downloadName = null;
    if (overrideName) {
      downloadName = bookId + "-" + overrideName;
    }
    
    for (const item of downloadList) {
      //item = downloadList[i];
      let count = item.passageFileNameUnfiltered?item.passageFileNameUnfiltered.length:0;
      if (downloadList.length === 1 && count === 1) {
        downloadName = bookId  + "-" + item.passageFileNameUnfiltered[0];
      } 
      if (!downloadName) {downloadName = bookId  + "-" + item.title};
      for (let j = 0; j < count; j++) {
        // console.log("downloadName", downloadName)
        // console.log("item.passageFileNameUnfiltered[j]",item.passageFileNameUnfiltered[j])
        // console.log("item.passageAudioUnfiltered[j]",item.passageAudioUnfiltered[j])
        exportList[fileCount]={};
        exportList[fileCount]["name"] = cleanFileName(item.passageFileNameUnfiltered[j]);
        exportList[fileCount]["url"] = item.passageAudioUnfiltered[j];
        fileCount++;
      }
    }
    // console.log("exportList:", exportList)
    // Download all the section passages into a single zip file
    // Use the section name as the zip file name
    if (exportList.length > 0) {

      if (exportList.length > 1) {
        if (!downloadName) {
          downloadName = (bookId? bookId+"-":"") + "download.zip"
        } else {
          downloadName = `${downloadName}.zip`
        }
      } 

      let file={};
      file.filelist = exportList;
      file.filename = cleanFileName(downloadName);
     
      fileDownloader(file);
    }
    return true;
}