import React, { useRef, useEffect, useState } from "react";
import { ElementCard } from "../ElementCard";
import { AppData } from "../../components/AppContext";
import { NOTE_MODE_CLOSED, NOTE_MODE_OPENED } from '../../constants.js';
import { downloadAudio } from "../../utils/downloadFunctions.js";
import { AudioNoteCard } from "../../components/AudioNoteCard";
import { pushSection,  pushMovementNote} from "../../utils/listFunctions.js";
import { RxChevronDown, RxChevronUp } from "react-icons/rx";
import { getBible } from '../../utils/localSettings';
import "./style.css";

const placeholderCards = [  
  {
    section: 1,
    title: "_"
  },
  {
    section: 2,
    title: "_"
  }
];

export const Sections = ({ className, cardType, bookId, isPending, parent, parentCode, sections, notes, isOBTHelps  }) => {
    
  const { storyMode, viewState, setCurrentSection, noteMode, toggleNoteMode, currentImage, fileDownLoader, isOpenCategory, bookType } = AppData();  
  const { bibleId, isBibleBrain } = getBible();
  // console.log("Sections - isBibleBrain:", isBibleBrain);
  
  
  /* List cards will track playable items and will be formatted as 
          {
              "key": unique key for the book
              "id": id for this type of card as returned by the API
              "index": index in the playlist,
              "parent": ${parent}-${parentCode}  (parent is chapters or movements and parentCode is the number)
              "section": section index in the playlist (may not be used)
              "sectionId": section id
              "title": title / name
              "passageAudio": list of audio files - filtered out chapter/title categories if disabled
              "passageAudioUnfiltered": list of all audio files regardless of category filters
              "passageFileName": list of audio file names - filtered out chapter/title categories if disabled
              "passageFileNameUnfiltered": list of all audio file names regardless of category filters
              "image": image file,
              "audioTitle": title audio,
              "passage": if note, this is the passage number
              "category": category name for notes,
              "categoryId": category id for notes,
              "color": color for note category,
              "duration": play duration
              "show":true/false to show this card - used to filter notes by categories
            }
  */
  const passageListRef = useRef([]);
  const notesListRef = useRef([]);
  const cardTrackIndex = useRef(0);
  const [notesList, setNotesList] = useState([]);
  const [passageList, setPassageList] = useState([]);
      
  /**
   * 
   * @param {*} sectionIndex - Index of the section to download
   */
  const downloadSection = async (sectionIndex) => {
    // console.log("Sections - downloadSection - sectionIndex", sectionIndex);
    let filteredList1 = [];
    let filteredList2 = [];
    let filteredList = [];
    // Lookup the passages for the section in the passageList and notesList        
    filteredList1 = passageListRef.current.filter((card) => card.section === sectionIndex);
    filteredList2 = notesListRef.current.filter((card) => card.section === sectionIndex);
    filteredList = filteredList1.concat(filteredList2);

    if (filteredList.length > 0) {
      let downloadName = "";
      if (filteredList[0].title) {
        downloadName = filteredList[0].title;
      } else {
        downloadName = "Sect-"+sectionIndex;
      }
      // console.log("Sections - downloadSection - filteredList", filteredList);
      // console.log("downloadAudio - Sections sdownloadSection - section");
      downloadAudio('section', fileDownLoader.current, filteredList, bookId, downloadName, filteredList[0].sectionId, filteredList[0].parent, filteredList[0].section, null);
    }    
    
  }

  const loadNotes = () => {
    if (!isPending && notes && notes.length > 0) {
      // console.log("Loading notes", notes);
      notes.forEach(card => {
        cardTrackIndex.current++;
        pushMovementNote(card,passageListRef.current,cardTrackIndex.current,bibleId,bookId,bookType,parent,parentCode?parentCode:0,currentImage?currentImage:null,noteSectionIndex.current);
        // Sections start with 0 and increment by 0.1 for each card to make them unique within the movement.  
        noteSectionIndex.current = Math.round((noteSectionIndex.current + 0.1) * 10) / 10; // Use math.round to ensure exact decimal increements wihtout rounding errors.
      });
    }
  }

  const loadPassages = () => {
    if (!isPending && sections && sections.length > 0) {
      // console.log("Loading sections", sections);
      sections.forEach(card => {
        cardTrackIndex.current++;
        pushSection(card,passageListRef.current,notesListRef.current,cardTrackIndex.current,bibleId,bookId,bookType,parent,parentCode?parentCode:0,currentImage?currentImage:null,isOBTHelps);
        // console.log("Section passages:", passageListRef.current);
      });
    }
  }
  const noteSectionIndex = useRef(0);
  const fcnLoadNotes = useRef();
  const fcnLoadPassages = useRef();
  fcnLoadNotes.current = loadNotes;
  fcnLoadPassages.current = loadPassages;

  /**
   * useEffect to preload notes and sections before remdering
   */
  useEffect(() => {
    const loadData = async () => {
      notesListRef.current = [];
      passageListRef.current = [];
      await fcnLoadNotes.current();
      await fcnLoadPassages.current();
      setNotesList(notesListRef.current);
      setPassageList(passageListRef.current);
    }
    if (!isPending && !isOpenCategory) {
      // console.log("Sections - useEffect:", sections);
      loadData();
    }
  }, [sections, notes, isPending, isOpenCategory]);


  //----------------------------------------------------------------
  // BEGIN render of element and note cards
  //----------------------------------------------------------------
  // Count sections and tracks
  let sectionIndex = 0;    // begins with section 1
  let thisTrackIndex = 0;  // for cardIndex value, which begins with track 1
  let thisKey = "";   

  return (
  <div className={`sections ${storyMode} ${viewState} ${className}`}>
    <div className="frame-sections">

      {/*------------------------------------------------------------------------------------------------
          IsPending -- Shimmer Animation until we have data
        ------------------------------------------------------------------------------------------------*/}
      {isPending && placeholderCards.map(card => {
        sectionIndex++;
        thisTrackIndex++;
        thisKey = `${bookId}-${parent}-${parentCode?parentCode:0}-TempSection-${card.section}`;
        return ( 
          <div className={`section-cards Section-${sectionIndex} skeleton-box`} id={`section-${card.section}}`} key={thisKey}>
            <ElementCard
              key={thisKey}
              //cardType="section"
              cardType={cardType}
              cardAnimation = "shimmer"
              cardName={card.title}
              // cardIndex={card.section}
              cardParent = {null}
              cardSection = {sectionIndex}
              cardIndex = {thisTrackIndex}
              cardDuration="_"
              cardImageClassName={`card-image-${viewState}`}
              cardAudioTitle={null}
              cardState={viewState}                
              obtDownloadClassName={`card-download-${viewState}`}
              showPlay={false}
              showDownload={false}
              overlapGroupClassName="instance-node"
              subheadingClassName="element-card-subheading" 
              playAudioOnClick={false}
              showAddToPlayList={false}
            />
          </div>
        );
      })}

      {/*------------------------------------------------------------------------------------------------
          Render the passages and notes for each section 
        ------------------------------------------------------------------------------------------------*/}
      {!isPending && passageList && passageList.length > 0 && passageList.map(card => {
        let thisKey = card.key
        // Get all the notes for this section
        let sectionNotes = [];
        if (notesList && notesList.length > 0) {
           sectionNotes = notesList.filter(note => note.section === card.section)
        }
        return ( 
          <div className="section-cards" id={`section-${card.section}}`} key={thisKey}>
            <ElementCard
              key={card.key}
              cardKey={card.key}
              cardId={card.id}
              cardType={cardType}
              cardName={card.title}
              cardIndex={card.index}
              cardParent={card.parent}
              cardSection={card.section}
              cardSectionId={card.sectionId}
              cardDuration={card.duration}
              cardImage={card.image}
              cardColor={card.color}
              cardAudioTitle={card.audioTitle}
              cardPassageAudio={card.passageAudio}
              cardPassageFileName={card.passageFileName}
              cardPassage={card.passage}
              cardCategory={card.category}
              cardCategoryId={card.categoryId}
              cardImageClassName={`card-image-${viewState}`}
              cardState={viewState}                
              obtDownloadClassName={`card-download-${viewState}`}
              showPlay={false}
              obtQuickPlayClassName={`card-quickplay-${viewState}`}
              overlapGroupClassName="instance-node"
              subheadingClassName="element-card-subheading" 
              navigateTo="/sections"                 
              playAudioOnClick={true}
              setCurrentCardAction={() => setCurrentSection}
              showDownload={isBibleBrain?false:true}
              downloadAction={() => downloadSection}
              cardNotes={sectionNotes}
              showAddToPlayList={true}
              scriptureAddress={card.scriptureAddress}
              imageOverlay={card.imageOverlay}
            />

            

            {sectionNotes.length > 0 &&
              <div className="audio-notes" key={thisKey}>
                <div className={`frame-audio-notes`}>
                  <div className="frame-note-heading">
                    <div className="text-wrapper">Audio Notes</div>
                    {noteMode === NOTE_MODE_CLOSED && 
                      <RxChevronDown className="expand-audio-notes" onClick={toggleNoteMode}/>
                    } 
                    {noteMode === NOTE_MODE_OPENED && 
                      <RxChevronUp className="expand-audio-notes" onClick={toggleNoteMode}/>
                    }
                  </div>
                  <div className="frame-note-cards">
                    {sectionNotes.map(note => {
                      thisKey=note.key;
                      return (
                        <AudioNoteCard
                          key={thisKey}
                          className="audio-note-card-instance"
                          cardId={note.id}
                          cardKey={note.key}
                          cardType={cardType}
                          cardName={note.title}
                          cardIndex={note.index}
                          cardParent={card.parent}
                          cardSection={note.section}
                          cardSectionId={note.sectionId}
                          cardDuration={note.duration}
                          cardImage={note.image}
                          cardColor={note.color}
                          cardAudioTitle={note.audioTitle}
                          cardPassageAudio={note.passageAudio}
                          cardPassageFileName={note.passageFileName}
                          cardPassage={note.passage}
                          cardCategory={note.category}
                          cardCategoryId={note.categoryId}
                          cardViewMode={noteMode}
                          playAudioOnClick={true}
                          setCurrentCardAction = {() => setCurrentSection}
                        />
                      );   
                    })}   
                  </div>
                </div>
              </div>
            }
          </div>
        );
      })}      

    </div> 
  </div>
  );
}

